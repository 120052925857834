@charset "utf-8";

body {
  /*padding-top: 50px;*/
}

.ach-container {
  /* padding: 40px 15px;*/
}

footer > div > p {
    color: #999;
}

.donate {
    margin-top: 20px;
}

.gadd {
    margin-bottom: 20px;
}

.site-sidebar {
	padding-right: 15px;
	padding-left: 15px;
}
.sidebar-header {
	margin: 40px 0 20px;
	border-bottom: 1px solid #eee;
}
.paypal-form {
}
.sidebar-nav {
	list-style: none;
	padding-left: 0;
}
.sidebar-gadd {
	margin-top: 20px;
}

.social-media {
	float: left;
	padding-left: 1.5rem;
}
